import App from './App.vue'
import {createApp } from 'vue'

import router from './router'
import store from './store'
import vuex from 'vuex'

import { createI18n} from 'vue-i18n'

import {createVuetify } from 'vuetify'
import en from 'vuetify/lib/locale/en'
import da from 'vuetify/lib/locale/da'
import se from 'vuetify/lib/locale/sv'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
    ssr: false,
    theme: {
        defaultTheme: 'light',
    },
})

const messages = {
    en: {
        ...require('@/locales/en.json'),
        vuetify: en
    },
    da: {
        ...require('@/locales/da.json'),
        vuetify: da
    },
    se: {
        ...require('@/locales/se.json'),
        vuetify: se
    }
}
const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'da',
    messages
})

const app =createApp(App)
    .use(router)
    .use(vuetify)
    .use(store)
    .use(vuex)
    .use(i18n)

app.mount('#app')
