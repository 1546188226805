import {callApi} from '@/api/apibase'

export const GetPaymentWindowLink = function(apiUrl, orderIdentifier, acceptUrl, declineUrl, receiptEmail){
    const requestData = {
        OrderIdentifier: orderIdentifier,
        AcceptUrl: acceptUrl,
        DeclineUrl: declineUrl,
        CustomerReceiptEmail: receiptEmail
    }
    return callApi(apiUrl, 'POST', '/api/shop/payments/freepay/getpaymentlink', requestData)
}