import { GetOrderByOrderIdentifier as apiGetOrderByOrderIdentifier } from '@/api/Orders/GetOrderByOrderIdentifier'
import { CheckOrderStatus as apiCheckOrderStatus } from '@/api/Orders/CheckOrderStatus'
import { GetPaymentWindowLink as apiGetPaymentWindowLink } from '@/api/Orders/GetPaymentWindowLink'
import { UpdateOrderStatus as apiUpdateOrderStatus} from '@/api/Orders/UpdateOrderStatus'
import { CreateOrderPayment as apiCreateOrderPayment } from '@/api/Orders/CreateOrderPayment'
import {UpdateOrderAddInvoiceEmail as apiUpdateOrderAddInvoiceEmail} from '@/api/Orders/UpdateOrderAddInvoiceEmail'
import { GetWebshopProducts as apiGetWebshopProducts } from '@/api/Orders/GetWebshopProducts'
import { CreateOrderChooseProducts as apiCreateOrderChooseProducts } from '@/api/Orders/CreateOrderChooseProducts'

export default {
    state: {
        apiUrlDictionary: {
            AffaldPlus: 'https://aprecycleapi.recycle.opti-lpr.dk',
            ARC: 'https://arc.recycleapi.recycle.opti-lpr.dk',
            Arwos: 'https://arwosrecycleapi.recycle.opti-lpr.dk',
            HelsingoerForsyning: 'https://helsingoerforsyningrecycleapi.recycle.opti-lpr.dk',
            HMAB: 'https://hassleholmrecycleapi.opti-lpr.dk', // Hässleholm Miljö AB
            HorsensKommune: 'https://horsenskommunerecycleapi.opti-lpr.dk/',
            IkastBrande: 'https://ikast-branderecycleapi.opti-lpr.dk/',
            Middelfartkommune: 'https://middelfartkommunerecycleapi.recycle.opti-lpr.dk',
            NordfynsKommune: 'https://nordfynskommunerecycleapi.recycle.opti-lpr.dk',
            Nordvaerk: 'https://nordvaerkrecycleapi.recycle.opti-lpr.dk',
            Norfos: 'https://norfosrecycleapi.recycle.opti-lpr.dk',
            Odsherred: 'https://odsherredrecycleapi.recycle.opti-lpr.dk',
            Refa: 'https://refarecycleapi.recycle.opti-lpr.dk',
            sam: 'https://samrecycleapi.opti-lpr.dk', // Samverkan Attervinnings Miljö  
            Sbc: 'https://sbcrecycleapi.recycle.opti-lpr.dk',
            Fredericia: 'https://fredericiarecycleapi.recycle.opti-lpr.dk',
            Vestfor: 'https://vestforbraendingenrecycleapi.opti-lpr.dk',
            Demo: 'https://demorecycleapi.dev.optidata.dk',
            Dev1: 'https://dev1recycleapi.dev.optidata.dk',
            Dev2: 'https://dev2recycleapi.dev.optidata.dk',
            Dev3: 'https://dev3recycleapi.dev.optidata.dk',
            SeOptidata: 'https://serecycleapi.dev.optidata.dk',
            SeDemo: 'https://swedendemorecycleapi.dev.optidata.dk',
            Optidata: 'https://recycleapicore.dev.optidata.dk'
        }
    },
    getters: {
    },
    actions: {
        getApiUrlDictionary:(context, payload) => {
            return context.state.apiUrlDictionary[payload.key] 
        },
        fetchOrderByOrderIdentifier: (context, payload) => {
            return apiGetOrderByOrderIdentifier(context.state.apiUrlDictionary[payload.companyName] , payload.orderIdentifier)
                .then(response => {
                    return response
                })
        },
        fetchOrderStatusByOrderIdentifier: (context, payload) => {
            return apiCheckOrderStatus(context.state.apiUrlDictionary[payload.companyName], payload.orderIdentifier)
                .then(response => {
                    return response.data
                })
        },
        fetchPaymentWindowLink: (context, payload) => {
            return apiGetPaymentWindowLink(context.state.apiUrlDictionary[payload.companyName], payload.orderIdentifier, payload.acceptUrl, payload.declineUrl, payload.receiptEmail)
                .then(response => {
                    return response.data
                })
        },
        updateOrderStatus: (context, payload) => {
            return apiUpdateOrderStatus(context.state.apiUrlDictionary[payload.companyName], payload.orderId, payload.orderStatus)
                .then(response => {
                    return response.data
                })
        },
        createOrderPayment: (context, payload) => {
            return apiCreateOrderPayment(context.state.apiUrlDictionary[payload.companyName], payload.registrationNumber, payload.origin)
                .then(response => {
                    return response.data
                })
        },
        updateOrderAddInvoiceEmail: (context, payload) => {
            return apiUpdateOrderAddInvoiceEmail(context.state.apiUrlDictionary[payload.companyName], payload.orderIdentifier, payload.email)
                .then(response => {
                    return response.data
                })
        },
        fetchWebshopProducts: (context, payload) => {
            return apiGetWebshopProducts(context.state.apiUrlDictionary[payload.companyName], payload.orderIdentifier)
                .then(response => {
                    return response.data
                })
        },
        createOrderChooseProducts: (context, payload) => {
            return apiCreateOrderChooseProducts(context.state.apiUrlDictionary[payload.companyName], payload.registrationNumber, payload.productIdAndAmounts)
                .then(response => {
                    return response.data
                })
        }
        
    }
}