import axios from 'axios'

export const callApi = function(apiUrl, method, endpoint, body, overriddenHeaders, overriddenBody){

    //Use the process API URL. Use this for local development
    if(process.env.VUE_APP_API_URL && apiUrl != 'https://demorecycleapi.dev.optidata.dk' && apiUrl != 'https://serecycleapi.dev.optidata.dk' && apiUrl != 'https://swedendemorecycleapi.dev.optidata.dk'){
        apiUrl = process.env.VUE_APP_API_URL
    }
    
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
    if(overriddenHeaders){
        headers = overriddenHeaders
    }

    // Create the request
    const request = {
        method,
        url: apiUrl + endpoint,
        headers: headers,
        cache: 'no-cache'
    }

    // If a login token is present. Put it as a header
    if (localStorage.getItem('token')) {
    // If we have an authorization token. put it in headers
        request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    // If a body is present - put it in the request
    if (body) {
        request.data = JSON.stringify(body)
    }

    if(overriddenBody) {
        request.data = overriddenBody
    }
    return axios(request)
        .then(response => {            
            return response
        })
        .catch(error => {
            return error           
        })
}