import { createStore } from 'vuex'

import Orders from './store/modules/orders'

const modules = {
    orders: Orders
}

const store = createStore({
    strict: true,
    modules,
    actions: {
        
    }
})

export default store